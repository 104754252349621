<template>
    <div>
        <div class="gallery-container">

            <h3>I don't like Instagram and VSCO is shit now so</h3>
            <h1>These are some photos</h1>
            <div class="photo-container">
                <img src="/photos/IMG_1123.jpg">
                <img src="/photos/DSCF0104.jpg">
                <img src="/photos/DSCF0112.jpg">
                <img src="/photos/DSCF0043.jpg">
                <img src="/photos/DSCF0035.jpg">
                <img src="/photos/DSCF0048.jpg">
                <img src="/photos/guitar.jpg">
                <img src="/photos/unnamed.jpg">
                <img src="/photos/000234240005 (1).jpg">
                <img src="/photos/000234240025.jpg">
                <img src="/photos/000234240008.jpg">
                <img src="/photos/IMG_0245.jpg">
                <img src="/photos/IMG_9946.jpg">
                <img src="/photos/IMG_9738.jpg">
                <img src="/photos/IMG_9633.jpg">
                <img src="/photos/IMG_9336.jpg">
                <img src="/photos/IMG20231123105744.jpg">
                <img src="/photos/IMG_9083.jpg">
                <img src="/photos/IMG_8711.jpg">
                <img src="/photos/IMG_8473.jpg">
                <img src="/photos/IMG_8304.jpg">
                <img src="/photos/IMG_7794.jpg">
                <img src="/photos/IMG_7041.jpg">
                <img src="/photos/IMG_6807.jpg">
                <img src="/photos/IMG_6587.jpg">
                <img src="/photos/IMG_6576.jpg">
                <img src="/photos/IMG_6539.jpg">
                <img src="/photos/IMG_7330.jpg">
                <img src="/photos/IMG_7197.jpg">
                <img src="/photos/IMG_7321.jpg">
                <img src="/photos/IMG_7301-VSCO.jpg">
                <img src="/photos/IMG_7344.png">
                <img src="/photos/IMG_6906.jpg">
                <img src="/photos/IMG_5710.jpg">
                <img src="/photos/IMG_5566.jpg">
                <img src="/photos/IMG_7670.jpg">
                <img src="/photos/IMG_4535.jpg">
                <img src="/photos/IMG_6152-VSCO.jpg">
                <img src="/photos/IMG_5317.jpg">
                <img src="/photos/IMG_0477.jpg">
                <img src="/photos/IMG_20191211_115235-VSCO.jpg">
                <img src="/photos/IMG20191205145500.jpg">
                <img src="/photos/P1050988-VSCO.jpg">
                <img src="/photos/IMG20191205173330.jpg">
                <img src="/photos/P1060005.jpg">
                <img src="/photos/IMG_20191206_113250.jpg">
                <img src="/photos/IMG20191206130737.jpg">
                <img src="/photos/IMG20191204112835.jpg">
                <img src="/photos/IMG20191203113145-VSCO.jpg">
                <img src="/photos/IMG20191116192705-VSCO.jpg">
                <img src="/photos/969-VSCO.jpg">
                <img src="/photos/CSHR.jpg">
                <img src="/photos/received_490326714679845.jpeg">
                <img src="/photos/P1040058.jpg">
                <img src="/photos/RC29MNY.jpg">
                <img src="/photos/3906-VSCO.jpg">
                <img src="/photos/IMG_0185-VSCO.jpg">
                <img src="/photos/IMG_1962-VSCO.jpg">
                <img src="/photos/IMG_8575-VSCO.jpg">
            </div>
            </div>
      <address>
         <button @click="goHome">For business enquiries</button>
      </address>  
    </div>
  </template>
  
  <script>
  export default {
        methods: {
        goHome() {
        this.$router.push('/');
        }
    },
    name: 'TopBar',
    props: {
      msg: String
    },
  }
  </script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    .gallery-container {
    position: fixed; /* Or absolute, depending on your overall layout needs */
    top: 0%; /* Start below any fixed UI elements you might have */
    left: 5%;
    right: 5%;
    bottom: 7%; /* Adjust based on your layout, consider space for an address bar or footer */
    overflow-x: hidden; /* Prevent horizontal scrolling */
    overflow-y: auto; /* Enable vertical scrolling */
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    }

    .gallery-container::-webkit-scrollbar {
    display: none; /* Hide scrollbar for Chrome, Safari, and Opera */
    }

    h1 {
        text-decoration: underline;
        position: relative; /* Changed from 'fixed' to 'relative' to scroll with .gallery-container */
        top: initial; /* Resetting 'top' as it's not needed with 'position: relative' */
        margin-top: -1% ; /* Adjust as needed based on your layout */        
        margin-bottom: 2.5% ; /* Adjust as needed based on your layout */

        }

    h3 {
    color: black;
    font-weight: 500;
    position: relative; /* Changed from 'fixed' to 'relative' to scroll with .gallery-container */
    top: initial; /* Resetting 'top' as it's not needed with 'position: relative' */
    width: 100%;
    font-size: medium;
    margin-top: 2.5%; /* Keeping your original margin-top for h3 */
    }

    .photo-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr); /* Adjusted to 3 columns as per your comment */
    gap: 10px;
    justify-items: center;
    align-items: center;
    padding-left: 3%;
    padding-right: 3%;
    /* Removed 'position: fixed' and positioning properties to allow natural flow within .gallery-container */
    }

    .photo-container img {
    width: 100%;
    height: 400px; /* Fixed height, adjust as needed */
    object-fit: cover;
    }



    @media (max-width: 600px) {
        .photo-container {
            grid-template-columns: repeat(1, 1fr); /* Change to 3 columns for mobile devices */
        }
    }
    
    address {
        position: fixed;
        font-style: normal;
        bottom: 2%;
        left: 0;
        right: 0;
        width: auto;
        text-align: center; /* Ensures the text within the address tag is centered */
        margin: 0 auto; /* Centers the element horizontally */    
    }
    
    button {
        font-size: 90%;
        width: auto;
        color: blue;
        background-color: transparent;
        border-color: transparent;
        cursor: default; /* Ensures cursor is default when not hovering */
        font: 15px Helvetica, sans-serif; /* Adjusted to include font-size for clarity */
        font-weight: 750;
    }
    
    button:hover {
        color: var(--color-hover);
        cursor: pointer; /* Changes cursor to pointer on hover */
    } 

    /* Hide scrollbar for Chrome, Safari, and Opera */
    .photo-container::-webkit-scrollbar {
    display: none;
    }
    
  </style>
  