<template>
    <div>
        <ul>
            <h1>Page not found, <a href="/">go home</a></h1>
            <br><br><br>
            <!--<iframe src="https://giphy.com/embed/BXjqytvu9bKzCUHdzz" width=50% height="480" frameBorder="0" class="giphy-embed" allowFullScreen></iframe><p><a href="https://giphy.com/stickers/cat-vibing-BXjqytvu9bKzCUHdzz"></a></p> -->
        </ul>
    </div>
</template>

<script>
    export default {
        name: 'NotFound',

    }
</script>

<style scoped>


</style>