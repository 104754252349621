import { createApp } from 'vue'
import App from './App.vue'
import { createRouter, createWebHistory } from 'vue-router' 
import "./assets/main.css" // Assuming your CSS file is in src/assets/css


// Import components
import NotFound from "./components/NotFound"
import QuizPage from "./components/QuizPage"
import TopBar from "./components/TopBar"
//import SelectionArea from "./components/SelectionArea"
import GalleryView from "./components/GalleryView"
//import SelectionArea from "./components/SelectionArea"


const router = createRouter({
  history: createWebHistory(), // Create a web history for the router
  routes: [
    { path: '/quiz/:id', components: { default: QuizPage, top: TopBar } },
    { path: '/personal-gallery', components: { default: GalleryView } },
    { path: '/', components: {default: GalleryView}},
    { path: '/:catchAll(.*)', component: NotFound }, // Use the catch-all route for 404 pages
  ],
});

const app = createApp(App);

app.use(router); // Use the router

app.mount('#app');
