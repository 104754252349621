<template>
  <div id="app">
    <router-view name="top"></router-view>
    <router-view></router-view>
  </div>
</template>

<script>


export default {
  name: 'App',
  components: {

  }
}

</script>

<style>
#app {
  display: flex;
  flex-direction: column;
  min-height: 98vh; /* Make sure it covers the full viewport height */
  font: Helvetica;
  font-weight: 1000;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: black;
}
</style>